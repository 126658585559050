import React from "react"
import Title from "../components/Title"
import pageStyles from "./page.module.scss"

const AboutusPage = () => (
  <>
    <div className={pageStyles.cover} />
    <div className={pageStyles.container}>
      <Title>Nosotros</Title>

      <h2>¿Quién es Kronos Seguros?</h2>
      <p>
        Somos un grupo de agentes certificados ante la CNSF (Comisión Nacional
        de Seguros y Fianzas) que buscan formentar la cultura de prevención en
        México. Especialistas en cada uno de los ramos, para poder brindar una
        asesoría integral y siempre identificando la mejor opción para nuestros
        clientes.
      </p>

      <p>
        <b>Kronos</b> representa al <i>"TIEMPO"</i>, que para nosotros es el
        recurso más valioso que tenemos y que debemos de aprovechar al máximo a
        cada instante, es por eso que nuestra prioridad es darle a nuestros
        clientes las herramientas que trabajarán para ellos.
      </p>

      <p>
        Mientras nuestros clientes están ocupados disfrutando las buenas cosas
        de la vida, nosotros les daremos esa protección que estará ahí cuando
        más la necesiten, ese dinero con el que contarán para poder aprovechar
        una oportunidad que se les presente y no la dejen ir y esa tranquilidad
        que en el momento en que todo por lo que han trabajado corra un riesgo,
        pueda recuperar lo que se haya perdido.
      </p>

      <p>
        Ya no podemos hacer nada por el pasado, vivan el presente, que nosotros
        nos ocuparemos de su futuro.
      </p>

      <hr />

      <h3>Misión</h3>
      <p>
        Generar una cultura del ahorro y conciencia de protección en las
        familias mexicanas, enfoncándonos principalmente en la gente joven,
        haciéndoles ver que entre más jóvenes somos, menor es el costo de los
        productos y mayor es el beneficio que vamos a obtener a futuro.
      </p>

      <hr />

      <h3>Visión</h3>
      <p>
        Convertirnos en un referente en seguros para la gente joven, apoyándolos
        en sus proyectos financieros y metas a corto, mediano y largo plazo.
      </p>

      <hr />

      <h3>Valores</h3>
      <dl>
        <dt>Ética</dt>
        <dt>Profesionalismo</dt>
        <dt>Integridad</dt>
        <dt>Honestidad</dt>
        <dt>Responsabilidad</dt>
      </dl>
    </div>
  </>
)

export default AboutusPage
